import React, { MouseEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// material-ui
import Tooltip from "@mui/material/Tooltip";

// third-party
import { ColumnDef } from "@tanstack/react-table";

// project-import
import IconButton from "../../components/@extended/IconButton";

import { Backdrop, Box, Fade, Grid, Modal, Stack, Typography, Button, TextField, InputAdornment } from "@mui/material";
import { useMemo, useState } from "react";
import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";

import { CompanyType } from "../../AllTypes";
import DisplayTable from "../display-table-view";
import AddCompanyModal from "./company-helpers/AddCompanyModal";
import MainLayout from "../main-layout";
import axiosServices from "../../utils/axios";
import { CompanyEndpoints } from "../../AllLables";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import { Chip } from "@mui/material";
import AlertItemDelete from "../pages-helpers/AlertItemDelete";
import { alertMessage } from "../pages-helpers/AlertMessage";

export default function Company() {
  const [search, setSearch] = useState("");
  const [companies, setCompanies] = useState<CompanyType[]>();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const navigate = useNavigate();

  const emptyState = {
    name: "",
    shortName: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    address: "",
    address2: "",
  };
  const [selectedItem, setSelectedItem] = useState<CompanyType>(emptyState);
  const [itemDelete, setItemDelete] = useState<CompanyType>();
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const deleteAlertClose = () => setDeleteAlert(false);

  function setDataFunction() {
    axiosServices
      .get("/api/Company")
      .then((res) => {
        setCompanies(res.data.items);
      })
      .catch((err) => {
        alertMessage("Something went wrong ", "error");
      });
  }

  const toggleRefreshData = () => {
    setRefreshData(!refreshData);
  };

  useEffect(() => {
    setDataFunction();
  }, [refreshData]);

  const modalStyle = {
    position: "absolute",
    display: "flex",
    width: "auto",
    height: "auto",
    alignContent: "center",
    justifyContent: "center",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  const columns = useMemo<ColumnDef<CompanyType>[]>(
    () => [
      {
        header: "ID",
        accessorKey: "id",
        dataType: "text",
        enableSorting: false,
      },
      {
        header: "Name",
        accessorKey: "name",
        dataType: "text",
      },
      {
        header: "Email",
        accessorKey: "email",
        dataType: "text",
      },
      {
        header: "Locations",
        accessorKey: "locationCount",
        dataType: "amount",
        meta: {
          className: "cell-center",
        },
      },
      {
        header: "Users",
        accessorKey: "userCount",
        dataType: "amount",
        meta: {
          className: "cell-center",
        },
      },
      {
        header: "Status",
        accessorKey: "isActive",
        dataType: "text",
        meta: {
          className: "cell-center",
        },
        cell: (cell) => {
          switch (cell.getValue()) {
            case false:
              return <Chip color="error" label="Inactive" size="small" variant="light" />;
            case true:
              return <Chip color="success" label="Active" size="small" variant="light" />;
            default:
              return <Chip color="info" label="Pending" size="small" variant="light" />;
          }
        },
      },
      {
        header: "Actions",
        id: "actions",
        disableSortBy: true,
        // cell: EditAction,
        cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
              <Tooltip title="Edit">
                <IconButton
                  color="primary"
                  onClick={async (e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    console.log({ row });
                    navigate(`/admin/company/${row.original.id}`);
                  }}
                >
                  <EditOutlined />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Edit">
                <IconButton
                  color="primary"
                  onClick={async (e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setSelectedItem(row.original as CompanyType);
                    setIsEditing(true);
                    handleOpen();
                  }}
                >
                  <EditOutlined />
                </IconButton>
              </Tooltip> */}
              <Tooltip title="Delete">
                <IconButton
                  color="error"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setDeleteAlert(true);
                    setItemDelete(row.original as CompanyType);
                  }}
                >
                  <DeleteOutlined />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },
        meta: {
          className: "cell-center",
        },
      },
    ],
    []
  );

  function handleOpenAndEditing() {
    setSelectedItem(emptyState);
    setIsEditing(false);
    handleOpen();
  }

  function afterDelete() {
    deleteAlertClose();
    setTimeout(setDataFunction, 500);
  }

  return (
    <MainLayout>
      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          sx={{
            mt: "2%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Fade in={open}>
            <Box sx={modalStyle}>
              <Grid container>
                <AddCompanyModal
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  editingData={selectedItem}
                  endpoints={CompanyEndpoints}
                  toggleRefreshData={toggleRefreshData}
                  closeModal={() => setOpen(false)}
                />
              </Grid>
            </Box>
          </Fade>
        </Modal>
      </Box>
      <Box sx={{ mb: 5 }}>
        <Grid item container xs={12}>
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography variant="h2">Companies</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} alignItems="right">
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" startIcon={<PlusOutlined />} onClick={() => navigate(`/admin/company/0`)}>
                Add Company
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {!companies && (
        <Box sx={{ p: 5 }}>
          <Stack direction="row" justifyContent="center">
            <CircularWithPath />
          </Stack>
        </Box>
      )}

      {companies && (
        <Stack direction="column" spacing={1}>
          <TextField
            variant="outlined"
            value={search}
            sx={{ maxWidth: "250px" }}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined></SearchOutlined>
                </InputAdornment>
              ),
            }}
          ></TextField>
          <DisplayTable columns={columns} data={companies} search={search} modalToggler={handleOpenAndEditing} showAddBtn={true} />
        </Stack>
      )}

      <AlertItemDelete
        endpoints={CompanyEndpoints}
        deleteId={itemDelete?.id?.toString()}
        title="Company"
        deleteName={itemDelete?.name || ""}
        open={deleteAlert}
        handleClose={afterDelete}
      />
    </MainLayout>
  );
}
