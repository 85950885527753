// material-ui
import Tooltip from "@mui/material/Tooltip";

// third-party
import { ColumnDef } from "@tanstack/react-table";

// assets
import {
  Backdrop,
  Box,
  ButtonGroup,
  Fade,
  Grid,
  InputAdornment,
  InputLabel,
  Modal,
  Stack,
  Typography,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  Paper,
  Divider,
} from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { PrinterFilled } from "@ant-design/icons";

import { useLocation } from "react-router-dom";
import useConfig from "../../hooks/useConfig";

import { GetFormulaType, SelectSeriesType, FormulaListItemType } from "../../AllTypes";
import DisplayTable from "../display-table-view";
import MainLayout from "../main-layout";
import { Button } from "@mui/material";
import { FormulaEndpoints, SeriesEndpoints } from "../../AllLables";
import axiosServices from "../../utils/axios";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import { alertMessage } from "../pages-helpers/AlertMessage";
import Stop from "@mui/icons-material/Stop";

import { useAppSelector } from "../../redux/hook";
import { FormulaComponentItem } from "../../types/formulation";
import PrintFormulaModal from "./formulation-helpers/PrintFormulaModal";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import { UserTypeRedux } from "../../types/user";
import { RoleType } from "../../utils/utility";
import "./styles.css";
import useStickyState from "../../hooks/useStickyState";

function round(num: number): number {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

interface Recycleable {
  formulaId: number;
  formulaName: string;
  formulaRgbColors: string;
  formulaMasterFormula: boolean;
  formulaShareAcrossCompany: boolean;
  formulaAmountInGrams: number;
  formulaPercentage: number;
  components: RecycleableComponent[];
}

interface RecycleableComponent {
  componentId?: number;
  formulaId?: number;
  rgbColors?: string;
  name: string;
  description?: string;
  amountInGrams: number;
  newAmountInGrams: number;
  percentage: number;
}


export default function FormulaRecycle() {
  const { mode } = useConfig();

  const [includeMaster, setIncludeMaster] = useState(true);
  const [includeCompany, setIncludeCompany] = useState(true);
  const [includeUser, setIncludeUser] = useState(true);
  const [formula, setFormula] = useState<GetFormulaType>();
  const [recycleables, setRecycleables] = useState<Recycleable[]>();
  const [formulaIndexSelected, setFormulaIndexSelected] = useState(-1);
  const [recycleableComponents, setRecycleableComponents] = useState<RecycleableComponent[]>([]);
  let [totalWeight, setTotalWeight] = useState(1000);
  let [baseWeight, setBaseWeight] = useState(1000);

  const [recycleType, setRecycleType] = useState("recycleFrom");

  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  function formatNo(pct: number) {
    return formatter.format(pct);
  }

  function search() {
    let formulaId = selectedFormulaOption?.value;
    let endpoint = recycleType === "recycleFrom" ? "recycleableFrom" : "recycleableInto";
    setIsLoading(true);
    axiosServices
      .get(
        `/api/formula/${endpoint}/${formulaId}?includeMaster=${includeMaster}&includeCompany=${includeCompany}&includeUser=${includeUser}&seriesId=${selectedSeries?.value}`
      )
      .then((res) => {
        console.log(res.data);
        setRecycleables(res.data);
        setRecycleableComponents([]);
        setFormulaIndexSelected(-1);
        setSearched(true);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const [searched, setSearched] = useState(false);
  const [formulas, setFormulas] = useState<GetFormulaType[]>();
  const [formulaOptions, setFormulaOptions] = useState<FormulaListItemType[]>([]);
  const [selectedFormulaOption, setSelectedFormulaOption] = useState<FormulaListItemType | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState<boolean>(false);
  const [printFormula, setPrintFormula] = useState<GetFormulaType | null>(null);
  const [printComponents, setPrintComponents] = useState<FormulaComponentItem[]>([]);
  const user: UserTypeRedux = useAppSelector(({ user }) => user.userinfo as UserTypeRedux);

  const [series, setSeries] = useState<SelectSeriesType[]>();
  const [selectedSeries, setSelectedSeries] = useState<SelectSeriesType>();
  const [defaultSeriesId, setDefaultSeriesId] = useStickyState<string | null>(null, "defaultSeriesId");

  const modalStyle = {
    position: "absolute",
    display: "flex",
    width: "auto",
    height: "auto",
    alignContent: "center",
    justifyContent: "center",
    bgcolor: "background.paper",
    boxShadow: 24,
    overflowY: "auto",
  };

  function isAdmin() {
    return user.userLevelName === RoleType.ADMIN || user.userLevelName === RoleType.SUPER_ADMIN;
  }

  useEffect(() => {
    axiosServices
      .get(SeriesEndpoints.getForSelect)
      .then((res) => {
        setSeries(res.data);

        // Set the default series or the first series as selected
        if (!selectedSeries) {
          let ss = res.data[0];
          if (defaultSeriesId) {
            let defaultSeries = res.data.find((d: SelectSeriesType) => d.value === defaultSeriesId);
            if (defaultSeries) {
              ss = defaultSeries;
            }
          }
          setSelectedSeries(ss);
        }
      })
      .catch((err) => {
        alertMessage("Something went wrong ", "error", err);
      });
  }, []);

  function updateTotalWeight(baseW: number) {
    let selectedRecycleable = recycleables?.[formulaIndexSelected];

    if (selectedRecycleable?.formulaPercentage) {
      let totalW = baseW / selectedRecycleable.formulaPercentage;
      updateWeights(totalW);
    }
  }

  function updateBaseWeight(totalW: number) {
    let selectedRecycleable = recycleables?.[formulaIndexSelected];

    if (selectedRecycleable?.formulaPercentage) {
      let baseW = totalW * selectedRecycleable.formulaPercentage;
      baseW = round(baseW);
      setBaseWeight(baseW);
    }
  }

  // useEffect(() => {
  //   // Updates the default series id
  //   setDefaultSeriesId(selectedSeries?.value || null);
  // }, [selectedSeries]);

  function updateWeights(total: number) {
    if (recycleableComponents && total >= 0) {
      recycleableComponents.forEach((rc) => {
        rc.newAmountInGrams = rc.percentage * +total;
      });
      setRecycleableComponents(recycleableComponents);
    }
    total = round(total);
    setTotalWeight(total);
  }

  useEffect(() => {
    setSelectedFormulaOption(null);
    // setSelectedFormulaId(null);
    setFormula(undefined);

    if (selectedSeries) {
      axiosServices
        .get(`/api/Formula/GetAll?seriesId=${selectedSeries.value}`)
        .then((res) => {
          let formulas = res.data;
          setFormulas(formulas);
          let options: FormulaListItemType[] = formulas.map((f: GetFormulaType) => ({
            text: f.name,
            value: f.id,
            selected: false,
          }));
          options.sort((a, b) => {
            if (a.text < b.text) {
              return -1;
            }
            if (a.text > b.text) {
              return 1;
            }
            return 0;
          });
          setFormulaOptions(options);

          if (formulas?.[0]) {
            setFormula(formulas[0]);
            let fId = formulas[0].id || 0;
            let option = options.find((f) => f.value === fId);
            if (option) {
              setSelectedFormulaOption(option);
            }
          }
        })
        .catch((err) => {
          alertMessage("Something went wrong ", "error", err);
        });
    }
  }, [selectedSeries]);

  const columns = useMemo<ColumnDef<RecycleableComponent>[]>(
    () => [
      {
        header: "Component or Formula",
        accessorKey: "name",
        dataType: "text",
        cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center">
              {row.original?.rgbColors && <Stop sx={{ ml: "-9px", fontSize: "30px", color: `${row.original.rgbColors}` }} />}
              <span>{row.original?.name}</span>
            </Stack>
          );
        },
      },
      {
        header: "Percentage",
        accessorKey: "percentage",
        dataType: "percentage",
        cell: ({ row }) => {
          return <>{formatNo(row.original?.percentage * 100)}%</>;
        },
      },
      {
        header: "Amount",
        accessorKey: "newAmountInGrams",
        dataType: "text",
        cell: ({ row }) => {
          return <>{formatNo(row.original?.newAmountInGrams)} g</>;
        },
      },
    ],
    []
  );

  function openPrintModal() {
    setPrintComponents(
      recycleableComponents.map((rc) => ({
        amount: rc.percentage * 100,
        amountInGrams: rc.newAmountInGrams,
        componentId: rc.componentId ?? 0,
        formulaId: rc.formulaId ?? 0,
        componentName: rc.name,
        componentDescription: rc.description,
        id: 0,
        isActive: true,
        isDeleted: false,
      }))
    );

    let fId = recycleType === "recycleInto" ? selectedFormulaOption?.value : recycleables?.[formulaIndexSelected].formulaId;
    console.log({ fId, recycleType });
    setPrintFormula(formulas?.find((f) => f.id === fId) || null);
    setShowPrintModal(true);
  }

  // function setDataForRecycling() {
  //   if (selectedFormulaId) {
  //     setShowRecycleFormulaModal(true);
  //   } else {
  //     alertMessage("No Formula Selected", "error");
  //   }
  // }
  // function setDataForEditing() {
  //   if (selectedFormulaId) {
  //       setEdittingFormulaId(selectedFormulaId);
  //       setCloneFormula(false);
  //       handleOpen();
  //     // }
  //   } else {
  //     alertMessage("No Formula Selected", "error");
  //   }
  // }
  // function setDataForDeleting() {
  //   if (selectedFormulaId) {
  //     if (selectedFormula) {
  //       setItemDelete(selectedFormula);
  //       setDeleteAlert(true);
  //     }
  //   } else {
  //     alertMessage("No Formula Selected", "error");
  //   }
  // }

  // function setDataForAdding() {
  //   if (selectedSeries) {
  //     handleOpen();
  //   } else {
  //     alertMessage(`Select series before adding formula.`, "error");
  //   }
  // }

  // function setDataForCloning() {
  //   if (selectedFormulaId) {
  //       setEdittingFormulaId(selectedFormulaId);
  //       setCloneFormula(true);
  //       handleOpen();
  //   } else {
  //     alertMessage("No Formula Selected", "error");
  //   }

  // }

  // const handleChangeWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.value === "") {
  //     setweightValue(0);
  //   } else {
  //     setweightValue(parseInt(e.target.value));
  //   }
  // };

  return (
    <MainLayout>
      <Box>
        {/* {(open) && <Modal
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          sx={{
            mt: "2%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Fade in={open}>
            <Box sx={modalStyle}>
              <Grid container>
                <AddFormulaModal
                  open={open}
                  seriesId={parseInt(selectedSeries?.value || '0')}
                  formulaId={edittingFormulaId}
                  cloneFormula={cloneFormula}
                  closeModal={handleClose}
                  // // toggleRefreshData={toggleRefreshData}
                  endpoints={FormulaEndpoints}
                />

              </Grid>
            </Box>
          </Fade>
        </Modal>} */}

        {showPrintModal && (
          <Modal
            open={showPrintModal}
            onClose={() => setShowPrintModal(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
            sx={{
              mt: "2%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Fade in={showPrintModal}>
              <Box sx={modalStyle}>
                <Grid container>
                  <PrintFormulaModal
                    data={printComponents}
                    // open={isCloning}
                    weight={totalWeight}
                    formula={printFormula}
                    series={selectedSeries!}
                    showPrintModal={showPrintModal}
                    setShowPrintModal={setShowPrintModal}
                    // editingData={selectedItem}
                    // selectedSeries={selectedSeries as SelectSeriesType}
                    endpoints={FormulaEndpoints}
                    closeModal={() => setShowPrintModal(false)}
                  />
                </Grid>
              </Box>
            </Fade>
          </Modal>
        )}
      </Box>

      <Box sx={{ mb: 1 }}>
        <Grid item xs={12} sm={12} lg={12}>
          <Stack direction="row">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h2">Formula Recycle</Typography>
            </Box>
            {/* <ActionMenu /> */}
          </Stack>
        </Grid>
      </Box>

      <Grid item container xs={12} spacing={1}>
        <Grid item container sm={12} spacing={1}>
          <Grid item xs={12} sm={4}>
            <Stack spacing={1}>
              <InputLabel htmlFor="series">Series</InputLabel>
              {series && (
                <Autocomplete
                  fullWidth
                  disablePortal
                  id="combo-box-demo"
                  options={series as SelectSeriesType[]}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  disableClearable={true}
                  value={selectedSeries as SelectSeriesType}
                  onChange={(_e, newValue) => {
                    setSelectedSeries(newValue as SelectSeriesType);
                  }}
                  getOptionLabel={(label) => label.text}
                  renderInput={(params: any) => <TextField fullWidth {...params} placeholder="Select Series" />}
                />
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={8}>
            {/* <Stack direction="row" spacing={1}>
              <Stack direction="column" spacing={1}>
                <InputLabel>Recycle</InputLabel>
              </Stack> */}
            <Stack spacing={1}>
              <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
                <InputLabel htmlFor="formula">Recycle Formula</InputLabel>
                {recycleType === "recycleFrom" && <Typography variant="body2">See what can '{formula?.name}' recycle into</Typography>}
                {recycleType === "recycleInto" && <Typography variant="body2">See what you can recycle into '{formula?.name}'</Typography>}
              </Stack>
              {formulas && (
                <Autocomplete
                  fullWidth
                  id="combo-box-demo1"
                  getOptionLabel={(label) => label.text}
                  options={formulaOptions}
                  // options={formula?.formulaListItems as FormulaListItemType[] }
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  value={selectedFormulaOption as FormulaListItemType}
                  disableClearable={true}
                  onChange={(_e, newValue) => {
                    const formulaCmp = formulas.find((item) => item.id === parseInt(newValue!.value as string));
                    // setSelectedFormulaId(parseInt(newValue?.value as string));
                    setSelectedFormulaOption(newValue as FormulaListItemType);
                    setFormula(formulaCmp);

                    // setSelectedComponent(formulaCmp as GetFormulaType)
                    // setBoxColor(
                    //   formulaCmp?.rgbColors ||
                    //   null
                    // )
                  }}
                  renderOption={(props, option) => {
                    const formulaCmp = formulas.find((item) => item.id === parseInt(option.value as string));
                    const x: any = props;
                    delete x["key"];
                    return (
                      // <Box direction="row" alignItems="center" sx={{cursor:"pointer"}} >
                      <Fragment key={`a${option!.value}`}>
                        {formulaCmp && (
                          <Box {...x} sx={{ cursor: "pointer" }} component="li" display="inline">
                            <Stop sx={{ fontSize: "30px", color: `${formulaCmp?.rgbColors}` }} />
                            {option.text}
                            &nbsp;
                            {formulaCmp?.masterFormula && <Chip size="small" color="info" variant="outlined" label="Master"></Chip>}
                            {!formulaCmp?.masterFormula && formulaCmp.shareAcrossCompany && (
                              <Chip
                                size="small"
                                color="info"
                                variant="outlined"
                                label={`Company${formulaCmp.companyName ? ` (${formulaCmp.companyName})` : ""}`}
                              ></Chip>
                            )}
                            {!formulaCmp?.masterFormula && !formulaCmp.shareAcrossCompany && (
                              <Chip
                                size="small"
                                color="info"
                                variant="outlined"
                                label={`User${formulaCmp.companyName ? ` (${formulaCmp.companyName})` : ""}`}
                              ></Chip>
                            )}
                            {/* &nbsp;{(formulaCmp.shareAcrossCompany && (user?.userRole !== RoleType.SUPER_ADMIN)) && <FactoryIcon style={{ fontSize: '16px', color: '#aaa'}} />}
                          {((formulaCmp.shareAcrossCompany === false) && (user?.userRole !== RoleType.SUPER_ADMIN) && (formulaCmp.userOwnedFormula === true)) && <PersonIcon style={{ fontSize: '16px', color: '#aaa'}} />} */}
                          </Box>
                        )}
                      </Fragment>
                    );
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      fullWidth
                      {...params}
                      placeholder="Select Formula"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <ButtonGroup size="small">
                              <Button
                                onClick={() => {
                                  setRecycleType("recycleFrom");
                                }}
                                variant={recycleType === "recycleFrom" ? "contained" : "outlined"}
                                // style={{background: recycleType === "recycleFrom" ? '#509bc5' : ''}}
                                color="primary"
                              >
                                <Typography variant="body1" align="center">
                                  From
                                </Typography>
                              </Button>
                              <Button
                                onClick={() => {
                                  setRecycleType("recycleInto");
                                }}
                                variant={recycleType === "recycleInto" ? "contained" : "outlined"}
                                // style={{background: recycleType === "recycleInto" ? '#509bc5' : ''}}
                                color="primary"
                              >
                                <Typography variant="h6" align="center">
                                  Into
                                </Typography>
                              </Button>
                            </ButtonGroup>
                          </InputAdornment>
                        ),
                      }}
                      // InputProps={{
                      //   startAdornment: (
                      //     <InputAdornment position='start'>
                      //       AAA
                      //     </InputAdornment>
                      //   )
                      // }}
                    />
                  )}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
        <Grid item container sm={12} spacing={1}>
          <Grid item xs>
            <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
              <Stack direction="row" spacing={1}>
                <Stack spacing={1}>
                  <InputLabel>Base Formula Weight</InputLabel>
                  <TextField
                    sx={{ width: "130px" }}
                    value={baseWeight}
                    onChange={(e) => {
                      let newBaseWeight = +e.target.value;
                      setBaseWeight(newBaseWeight);
                      updateTotalWeight(newBaseWeight);
                      // updateWeightsWithBase(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">g</InputAdornment>,
                    }}
                  ></TextField>
                </Stack>
                <Stack spacing={1}>
                  <InputLabel>Include Types</InputLabel>
                  <ButtonGroup>
                    {/* TODO: create theme for custom color #509bc5 secondary or #95afe8 or #476bba main */}
                    {/* TODO: style with no elevation */}
                    <Button variant={includeMaster ? "contained" : "outlined"} color="primary" onClick={() => setIncludeMaster(!includeMaster)}>
                      Master
                    </Button>
                    <Button variant={includeCompany ? "contained" : "outlined"} color="primary" onClick={() => setIncludeCompany(!includeCompany)}>
                      Company
                    </Button>
                    <Button variant={includeUser ? "contained" : "outlined"} color="primary" onClick={() => setIncludeUser(!includeUser)}>
                      User
                    </Button>
                    {/* <Button variant={includeMaster ? "contained" : "outlined"} style={{background: includeMaster ? '#509bc5' : ''}} onClick={() => setIncludeMaster(!includeMaster)}>Master</Button>
                  <Button variant={includeCompany ? "contained" : "outlined"} style={{background: includeCompany ? '#509bc5' : ''}} onClick={() => setIncludeCompany(!includeCompany)}>Company</Button>
                  <Button variant={includeUser ? "contained" : "outlined"} style={{background: includeUser ? '#509bc5' : ''}} onClick={() => setIncludeUser(!includeUser)}>User</Button> */}
                  </ButtonGroup>
                </Stack>
              </Stack>
              <Button onClick={search} variant="contained">
                Search
              </Button>
            </Stack>
          </Grid>
        </Grid>

        <Grid item sm={12} sx={{ py: 2 }}>
          <Divider></Divider>
        </Grid>
        {isLoading && (
          <Box sx={{ p: 5, width: "100%" }}>
            <Stack direction="row" justifyContent="center">
              <CircularWithPath />
            </Stack>
          </Box>
        )}
        {!isLoading && searched && (
          <Grid item container sm={12} spacing={1}>
            <Grid item xs={12} sm={5}>
              <Paper elevation={1}>
                <List sx={{ py: 0 }}>
                  <ListSubheader
                    sx={{ lineHeight: "42px", bgcolor: mode === "light" ? "#fafafa" : "#141414", color: mode === "light" ? "#333" : "#ffffffde" }}
                  >
                    {recycleType === "recycleFrom" ? `Recycle from ${formula?.name} into` : `Recycle into ${formula?.name} from`}
                  </ListSubheader>
                  {recycleables?.map((r, ix) => (
                    <ListItem key={ix} sx={{ p: 0 }}>
                      <ListItemButton
                        selected={formulaIndexSelected === ix}
                        onClick={() => {
                          setFormulaIndexSelected(ix);
                          let components: RecycleableComponent[] = [];

                          if (recycleType === "recycleInto") {
                            components.push({
                              formulaId: r.formulaId,
                              name: r.formulaName,
                              description: r.formulaName,
                              rgbColors: r.formulaRgbColors,
                              amountInGrams: r.formulaAmountInGrams,
                              newAmountInGrams: r.formulaAmountInGrams,
                              percentage: r.formulaPercentage,
                            });
                          } else if (formula) {
                            components.push({
                              formulaId: formula?.id || 0,
                              name: formula?.name,
                              description: formula?.name,
                              rgbColors: formula?.rgbColors,
                              amountInGrams: r.formulaAmountInGrams,
                              newAmountInGrams: r.formulaAmountInGrams,
                              percentage: r.formulaPercentage,
                            });
                          }
                          components.push(...r.components);

                          let totalW = baseWeight / r.formulaPercentage;
                          components.forEach((rc) => {
                            rc.newAmountInGrams = rc.percentage * totalW;
                          });

                          setRecycleableComponents(components);
                          totalW = round(totalW);
                          setTotalWeight(totalW);
                          // setTimeout(() => updateWeights(totalWeight));
                          // setRecycleable(r);
                        }}
                      >
                        <Stop sx={{ fontSize: "30px", color: `${r.formulaRgbColors}` }} />
                        {r.formulaName}
                        &nbsp;
                        {r?.formulaMasterFormula && <Chip size="small" color="info" variant="outlined" label="Master"></Chip>}
                        {!r?.formulaMasterFormula && r.formulaShareAcrossCompany && (
                          <Chip size="small" color="info" variant="outlined" label={`Company`}></Chip>
                        )}
                        {!r?.formulaMasterFormula && !r.formulaShareAcrossCompany && (
                          <Chip size="small" color="info" variant="outlined" label={`User`}></Chip>
                        )}
                        &nbsp;
                        <b>{formatNo(r.formulaPercentage * 100)}%</b>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={7}>
              {(formulas || series) && !isLoading ? (
                <Stack direction="column" spacing={1} alignItems="stretch">
                  <Paper elevation={0}>
                    <DisplayTable
                      columns={columns}
                      // showFooter={true}
                      showPagination={false}
                      defaultSorting={[{ id: "percentage", desc: true }]}
                      data={recycleableComponents}
                      modalToggler={() => {}}
                      showAddBtn={false}
                    />
                  </Paper>
                  <Paper elevation={1}>
                    <Grid container spacing={1} sx={{ p: 1 }} alignItems="center">
                      <Grid item xs></Grid>
                      <Grid item>
                        <Typography variant="body1">Desired Weight</Typography>
                      </Grid>
                      <Grid item>
                        <TextField
                          sx={{ width: "130px" }}
                          value={totalWeight}
                          onChange={(e) => {
                            let totalW = +e.target.value;
                            updateWeights(totalW);
                            updateBaseWeight(totalW);
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">g</InputAdornment>,
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Print Formula" placement="top">
                          <span>
                            <Button
                              size="small"
                              sx={{}}
                              variant="contained"
                              onClick={openPrintModal}
                              disabled={formulaIndexSelected === -1}
                              startIcon={<PrinterFilled />}
                            >
                              Print
                            </Button>
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Paper>
                </Stack>
              ) : (
                <Box sx={{ p: 5 }}>
                  <Stack direction="row" justifyContent="center">
                    <CircularWithPath />
                  </Stack>
                </Box>
              )}
              <Grid container></Grid>
            </Grid>
          </Grid>
        )}
        {!isLoading && !searched && (
          <Grid item xs={12}>
            <Typography sx={{ pt: 2, pb: 3 }} align="center">
              No search has been made.
            </Typography>
          </Grid>
        )}

        <Grid item container sm={12} spacing={1}></Grid>
      </Grid>
    </MainLayout>
  );
}
