import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  TextField,
  Stack,
  Tooltip,
  Typography,
  IconButton,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { CloseOutlined } from "@ant-design/icons";
import { ComponentCostType, SelectInkType } from "../../AllTypes";

import axiosServices from "../../utils/axios";
import { alertMessage } from "../pages-helpers/AlertMessage";

interface Props {
  closeModal: (modified?: boolean) => void;
  // componentId?: number;
  companyId: number;
}

export default function SetComponentCostModal({ closeModal, companyId }: Props) {
  const [componentCost, setComponentCost] = useState<ComponentCostType>({} as ComponentCostType);
  const [componentId, setComponentId] = useState<string | null>(null);
  const [components, setComponents] = useState<SelectInkType[]>([]);
  const [selectedComponent, setSelectedComponent] = useState<SelectInkType | null>(null);

  let initialValues = {
    componentId,
    companyId,
    costPerGal: 0,
  };

  useEffect(() => {
    axiosServices
      .get("/api/Component/GetAllComponents")
      .then((res) => {
        setComponents(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      });
  }, []);

  useEffect(() => {
    if (!companyId || !componentId) {
      return;
    }
    axiosServices
      .get(`/api/componentCost?companyId=${companyId}&componentId=${componentId}`)
      .then((res) => {
        setComponentCost(res.data);
        setValues(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error");
      });
  }, [componentId, companyId]);

  const ItemSchema = Yup.object().shape({
    componentId: Yup.string().required("Component is required"),
    costPerGal: Yup.number().min(0, "Cost cannot be negative.").required("Cost is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ItemSchema,
    enableReinitialize: false,
    onSubmit: async (values, { setSubmitting }) => {
      axiosServices
        .post(`/api/componentCost`, values)
        .then((res) => {
          alertMessage(`Component cost modified successfully`, "success");
          closeModal(true);
        })
        .catch((err) => {
          alertMessage("Something went wrong", "error", err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const { values, setValues, errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue, handleChange } = formik;

  return (
    <Box
      sx={(theme) => ({
        maxHeight: "89vh",
        width: "85vw",
        [theme.breakpoints.only("xs")]: {
          width: "100vw",
        },
      })}
    >
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={handleSubmit}>
          <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
            <DialogTitle>
              <Typography variant="h4" component="span">
                Edit Cost
              </Typography>
            </DialogTitle>
            <Tooltip title="Close">
              <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={() => closeModal()} edge="start">
                <CloseOutlined />
              </IconButton>
            </Tooltip>
          </Stack>
          <Divider />
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} sx={{ pt: "0 !important" }}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="quantity">Component</InputLabel>

                      {components && (
                        <Autocomplete
                          size="small"
                          fullWidth
                          disablePortal
                          options={components}
                          // isOptionEqualToValue={(option, value) => option.value === value.value || option.value === components?.[0].value}
                          {...getFieldProps(`componentId`)}
                          disableClearable={false}
                          value={selectedComponent}
                          onChange={(_e, newValue) => {
                            const component = newValue as SelectInkType;
                            setSelectedComponent(component);
                            setFieldValue(`componentId`, component?.value);
                            setComponentId(component?.value);
                          }}
                          getOptionLabel={(label) => label.text}
                          renderInput={(params: any) => <TextField id={`componentId`} fullWidth {...params} placeholder={`Select Component`} />}
                        />
                      )}
                      {touched?.componentId && errors.componentId && <FormHelperText error>Select Component</FormHelperText>}
                    </Stack>
                  </Grid>
                  {/* <Grid item xs={12} sm={12} sx={{ pt: "0 !important" }}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="quantity">Component</InputLabel>
                      <Typography>{componentCost.componentName}</Typography>
                    </Stack>
                  </Grid> */}
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="costPerGal">Cost per Gal</InputLabel>
                        <TextField
                          fullWidth
                          type="number"
                          placeholder="Cost (per Gal)"
                          {...getFieldProps("costPerGal")}
                          error={Boolean(touched.costPerGal && errors.costPerGal)}
                          helperText={touched.costPerGal && errors.costPerGal}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 2.5 }}>
            <Button color="secondary" onClick={() => closeModal()}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" disabled={isSubmitting}>
              Save
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Box>
  );
}
