import { useEffect, useState, Dispatch, SetStateAction } from "react";

// material-ui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

// project imports
import CircularWithPath from "../../../components/@extended/progress/CircularWithPath";

// types
import { MenuItem, Select, SelectChangeEvent, Stack, Tooltip, Typography } from "@mui/material";
import { CloseOutlined } from "@ant-design/icons";
import { IconButton } from "@mui/material";
import { EndpointsType, LocationType, SelectCompanyType, UserType } from "../../../AllTypes";
import { OutlinedInput } from "@mui/material";
import { FormHelperText } from "@mui/material";
import { alertMessage } from "../../pages-helpers/AlertMessage";
import { CompanyEndpoints, LocationEndpoints } from "../../../AllLables";
import axiosServices from "../../../utils/axios";
import { insert, update } from "../../../api/generalRoute";
import useAuth from "../../../hooks/useAuth";
import { UserProfile } from "../../../types/auth";

interface Props {
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  editingData: UserType;
  endpoints: EndpointsType;
  toggleRefreshData: () => void;
  closeModal: () => void;
}

export default function AddUserModal({ isEditing, setIsEditing, editingData, endpoints, toggleRefreshData, closeModal }: Props) {
  const { user: currentUser } = useAuth();
  console.log({ currentUser });
  const [loading, setLoading] = useState<boolean>(true);
  const [tempIsEditing, setTempIsEditing] = useState<boolean>(isEditing ? true : false);
  const [companies, setCompanies] = useState<SelectCompanyType[]>([]);
  const [userLevelOptions, setUserLevelOptions] = useState<{ value: number; text: string }[]>([
    // TODO: move this
    { value: 1, text: "Company User" },
    { value: 2, text: "Team Admin" },
    { value: 3, text: "Company Admin" },
    { value: 4, text: "Admin" },
    { value: 6, text: "Dispenser" },
  ]);
  const [locations, setLocations] = useState<LocationType[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(tempIsEditing && editingData ? editingData.companyId : null);
  // TODO: move this
  const userLevels = {
    CompanyUser: 1,
    TeamAdmin: 2,
    CompanyAdmin: 3,
    Admin: 4,
    SuperAdmin: 5,
    Dispenser: 6,
  };

  function setPossibleUserLevelOptions() {
    // let newOptions = userLevelOptions.filter((u) => u.value <= (currentUser?.userLevel || 0));
    // valid levels
    let validLevels: number[] = [];
    if (currentUser?.userLevel === userLevels.TeamAdmin) {
      validLevels = [userLevels.CompanyUser];
    } else if (currentUser?.userLevel === userLevels.CompanyAdmin) {
      validLevels = [userLevels.CompanyUser, userLevels.TeamAdmin, userLevels.CompanyAdmin];
    } else if (currentUser?.userLevel === userLevels.Admin || currentUser?.userLevel === userLevels.SuperAdmin) {
      validLevels = [
        userLevels.CompanyUser,
        userLevels.TeamAdmin,
        userLevels.CompanyAdmin,
        userLevels.Admin,
        userLevels.SuperAdmin,
        userLevels.Dispenser,
      ];
    }

    let newOptions = userLevelOptions.filter((u) => validLevels.includes(u.value));

    setUserLevelOptions(newOptions);
  }

  useEffect(() => {
    setLoading(false);
    setPossibleUserLevelOptions();

    // Set default company if user cannot modify it
    if (!hasMinLevel(currentUser, "Admin")) {
      let companyId = tempIsEditing ? editingData?.companyId : currentUser?.companyId;
      setFieldValue("companyId", companyId);
      setSelectedCompanyId(companyId || 0);
    }

    // Set default location if user cannot modify it
    if (!hasMinLevel(currentUser, "CompanyAdmin")) {
      let locationId = tempIsEditing ? editingData?.locationId : currentUser?.locationId;
      setFieldValue("locationId", locationId);
    }
  }, [currentUser]);

  // TODO: move to helper class
  function hasMinLevel(user: UserProfile | null | undefined, userLevelName: string): Boolean {
    if (!user) {
      return false;
    }
    let orderedLevels = ["User", "TeamAdmin", "CompanyAdmin", "Admin", "SuperAdmin"];
    let ixLevel = orderedLevels.indexOf(user.userLevelName || "");
    let ixTestLevel = orderedLevels.indexOf(userLevelName);

    return ixLevel !== -1 && ixTestLevel !== -1 && ixLevel >= ixTestLevel;
  }

  // TODO: move to helper class
  function hasLevel(user: UserProfile | null | undefined, userLevelName: string): Boolean {
    if (!user || !user.userLevelName || !userLevelName) {
      return false;
    }
    return user.userLevelName === userLevelName;
  }

  useEffect(() => {
    axiosServices
      .get(CompanyEndpoints.getForSelect)
      .then((res) => {
        setCompanies(res.data);
        console.log("companies set");
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      });
  }, []);

  const ItemSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(255)
      .when("userLevel", {
        is: (userLevel: number) => userLevel !== userLevels.Dispenser,
        then: (schema) => schema.required("First Name is required"),
      }),
    lastName: Yup.string()
      .max(255)
      .when("userLevel", {
        is: (userLevel: number) => userLevel !== userLevels.Dispenser,
        then: (schema) => schema.required("Last Name is required"),
      }),
    email: Yup.string()
      .when("userLevel", {
        is: (userLevel: number) => userLevel !== userLevels.Dispenser,
        then: (schema) => schema.matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i, "Email Invalid").required("Email is required"),
      })
      .when("userLevel", {
        is: (userLevel: number) => userLevel === userLevels.Dispenser,
        then: (schema) => schema.required("Username is required"),
      })
      .max(40),
    // phoneNumber: Yup.string().matches(/^[0-9]{10}$/, "Phone no. must be of exactly 10 digits"),
    companyId: Yup.number()
      .nullable()
      .when("userLevel", {
        is: (val: number) => val < userLevels.CompanyAdmin || val === userLevels.Dispenser,
        then: (schema) => schema.required("Select Company Name").moreThan(-1, "Select Company Name"),
      }),
    locationId: Yup.number()
      .nullable()
      .when("userLevel", {
        is: (val: number) => val < userLevels.CompanyAdmin || val === userLevels.Dispenser,
        then: (schema) => schema.required("Select Location Name").moreThan(-1, "Select Location Name"),
      }),
  });

  const handleAlertClose = () => {
    setIsEditing(false);
    setTempIsEditing(false);
    toggleRefreshData();
    closeModal();
  };

  const userInitialValues = {
    firstName: tempIsEditing && editingData ? editingData.firstName : "",
    lastName: tempIsEditing && editingData ? editingData.lastName : "",
    email: tempIsEditing && editingData ? editingData.email : "",
    phoneNumber: tempIsEditing && editingData ? editingData.phoneNumber : "",
    title: tempIsEditing && editingData ? editingData.title : "",
    address: tempIsEditing && editingData ? editingData.address : "",
    address2: tempIsEditing && editingData ? editingData.address2 : "",
    companyId: tempIsEditing && editingData ? editingData.companyId : -1,
    locationId: tempIsEditing && editingData ? editingData.locationId : -1,
    city: tempIsEditing && editingData ? editingData.city : "",
    state: tempIsEditing && editingData ? editingData.state : "",
    country: tempIsEditing && editingData ? editingData.country : "",
    zip: tempIsEditing && editingData ? editingData.zip : "",
    userLevel: tempIsEditing && editingData ? editingData.userLevel : 1,
    // isCompanyAdmin: (tempIsEditing && editingData) ? editingData.isCompanyAdmin : false,
  };

  const formik = useFormik({
    initialValues: editingData && tempIsEditing ? { ...userInitialValues, id: editingData.id } : userInitialValues,
    validationSchema: ItemSchema,
    enableReinitialize: false,
    onSubmit: async (values, { setSubmitting }) => {
      // Validations
      // if logged user is teamadmin: company fixed, location fixed, userlevel is (company user)
      // if logged user is companyadmin: company fixed, userlevel is (company user | team admin | company admin)
      // if new user is admin or super admin: no company, no location

      if (!currentUser || !currentUser.userLevel) {
        alertMessage("Cannot create user without current user info", "error");
        setSubmitting(false);
        return;
      }

      if (currentUser.userLevel === userLevels.CompanyUser) {
        alertMessage("User cannot create or modify users.", "error");
        setSubmitting(false);
        return;
      }

      // if logged user is teamadmin: company fixed, location fixed, userlevel is (company user)
      if (currentUser.userLevel === userLevels.TeamAdmin) {
        values = {
          ...values,
          companyId: currentUser.companyId || -1,
          locationId: currentUser.locationId || -1,
        };

        if (values.userLevel > userLevels.CompanyAdmin) {
          alertMessage("User cannot create a user with this level.", "error");
          setSubmitting(false);
          return;
        }
      }

      // if logged user is companyadmin: company fixed, userlevel is (company user | team admin | company admin)
      if (currentUser.userLevel === userLevels.CompanyUser) {
        values = {
          ...values,
          companyId: currentUser.companyId || -1,
        };

        if (values.userLevel > userLevels.CompanyAdmin) {
          alertMessage("User cannot create a user with this level.", "error");
          setSubmitting(false);
          return;
        }
      }

      // if new user is admin or super admin: no company, no location
      if (values.userLevel === userLevels.Admin || values.userLevel === userLevels.SuperAdmin) {
        values = {
          ...values,
          companyId: null,
          locationId: null,
        };
      }

      try {
        if (tempIsEditing && editingData) {
          values = { ...values, id: editingData.id };
          update(endpoints, editingData.id?.toString(), values)
            .then(() => {
              alertMessage(`User updated successfully`, "success");
              handleAlertClose();
            })
            .catch((err) => {
              alertMessage("Something went wrong", "error", err);
              setSubmitting(false);
            });
        } else {
          insert(endpoints, values)
            .then(() => {
              alertMessage(`User added successfully`, "success");
              handleAlertClose();
              setSubmitting(false);
            })
            .catch((err) => {
              alertMessage("Something went wrong", "error", err);
              setSubmitting(false);
            });
        }
      } catch (error) {
        alertMessage("Something went wrong", "error");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue, setValues, handleChange } = formik;

  useEffect(() => {
    console.log("selectedCompanyId change");
    console.log(values);

    if (selectedCompanyId) {
      axiosServices
        .get(LocationEndpoints.getByCompanyId(selectedCompanyId))
        .then((res) => {
          setLocations(res.data);
          console.log(res.data);
        })
        .catch((err) => {
          alertMessage("Something went wrong", "error");
        });
    }
  }, [selectedCompanyId]);

  const handleChangeOfCompanyId = (e: SelectChangeEvent<number>) => {
    const val = parseInt(e.target.value.toString());
    if (!val) return;

    console.log("setSelectedCompanyId");
    console.log(values);
    setSelectedCompanyId(val);
  };

  function CompanyInput() {
    // TODO: maybe use helper for permission validations?
    if (currentUser?.userLevel && (values.userLevel === userLevels.Admin || values.userLevel === userLevels.SuperAdmin)) {
      return null;
    }

    return hasMinLevel(currentUser, "Admin") ? (
      <>
        <InputLabel htmlFor="companyId">Company</InputLabel>
        <Select
          id="companyId"
          input={<OutlinedInput error={Boolean(touched.companyId && errors.companyId)} />}
          {...getFieldProps("companyId")}
          onChange={(e) => {
            handleChange(e);
            handleChangeOfCompanyId(e);
          }}
        >
          {!companies && (
            <Box sx={{ p: 5 }}>
              <Stack direction="row" justifyContent="center">
                <CircularWithPath />
              </Stack>
            </Box>
          )}
          {companies &&
            companies?.map((item, index) => (
              <MenuItem key={index} value={parseInt(item.value)}>
                {item.text}
              </MenuItem>
            ))}
        </Select>
        {touched.companyId && errors.companyId && <FormHelperText error>{errors.companyId}</FormHelperText>}
      </>
    ) : (
      <>
        <InputLabel htmlFor="companyLabel">Company Name</InputLabel>
        <OutlinedInput fullWidth id="companyLabel" value={currentUser?.companyName} name="companyLabel" readOnly={true} />
      </>
    );
  }

  function LocationInput() {
    // TODO: maybe user helper for permission validations?
    if (currentUser?.userLevel && (values.userLevel === userLevels.Admin || values.userLevel === userLevels.SuperAdmin)) {
      return null;
    }
    return hasMinLevel(currentUser, "CompanyAdmin") ? (
      <>
        <InputLabel htmlFor="locationId">Location</InputLabel>
        <Select id="locationId" input={<OutlinedInput error={Boolean(touched.locationId && errors.locationId)} />} {...getFieldProps("locationId")}>
          {!locations && (
            <Box sx={{ p: 5 }}>
              <Stack direction="row" justifyContent="center">
                <CircularWithPath />
              </Stack>
            </Box>
          )}
          {locations &&
            locations?.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
        {touched.locationId && errors.locationId && <FormHelperText error>{errors.locationId}</FormHelperText>}
      </>
    ) : (
      <>
        <InputLabel htmlFor="locationLabel">Location</InputLabel>
        <OutlinedInput fullWidth id="locationLabel" value={currentUser?.locationName} name="locationLabel" readOnly={true} />
      </>
    );
  }

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <Box sx={{ maxHeight: "89vh" }}>
      <FormikProvider value={formik}>
        {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
        <Form noValidate onSubmit={handleSubmit}>
          <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
            <DialogTitle>
              <Typography variant="h4" component="span">
                {tempIsEditing ? "Edit User" : "Add User"}
              </Typography>
            </DialogTitle>
            <Tooltip title="Close">
              <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={closeModal} edge="start">
                <CloseOutlined />
              </IconButton>
            </Tooltip>
          </Stack>
          <Divider />
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="userLevel">User Level</InputLabel>
                      <Select
                        id="userLevel"
                        input={<OutlinedInput error={Boolean(touched.userLevel && errors.userLevel)} />}
                        {...getFieldProps("userLevel")}
                        // onChange={handleChange}
                        value={values.userLevel || ""}
                      >
                        {!userLevelOptions && (
                          <Box sx={{ p: 5 }}>
                            <Stack direction="row" justifyContent="center">
                              <CircularWithPath />
                            </Stack>
                          </Box>
                        )}
                        {userLevelOptions &&
                          userLevelOptions?.map((item, index) => (
                            <MenuItem key={index} value={parseInt(item.value + "")}>
                              {item.text}
                            </MenuItem>
                          ))}
                      </Select>
                      {touched.userLevel && errors.userLevel && <FormHelperText error>{errors.userLevel}</FormHelperText>}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Stack spacing={1}>
                      <CompanyInput></CompanyInput>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Stack spacing={1}>
                      <LocationInput></LocationInput>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  {values.userLevel !== userLevels.Dispenser && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="firstName">First Name</InputLabel>
                          <TextField
                            fullWidth
                            id="firstName"
                            placeholder="First Name"
                            {...getFieldProps("firstName")}
                            error={Boolean(touched.firstName && errors.firstName)}
                            helperText={touched.firstName && errors.firstName}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="lastName">Last Name</InputLabel>
                          <TextField
                            fullWidth
                            id="lastName"
                            placeholder="Last Name"
                            {...getFieldProps("lastName")}
                            error={Boolean(touched.lastName && errors.lastName)}
                            helperText={touched.lastName && errors.lastName}
                          />
                        </Stack>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="email">{values.userLevel === userLevels.Dispenser ? "Username" : "Email"}</InputLabel>
                      <TextField
                        fullWidth
                        id="email"
                        placeholder={values.userLevel === userLevels.Dispenser ? "Username" : "Email"}
                        {...getFieldProps("email")}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Stack>
                  </Grid>
                  {values.userLevel !== userLevels.Dispenser && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="title">Title</InputLabel>
                          <TextField
                            fullWidth
                            id="title"
                            placeholder="Title"
                            {...getFieldProps("title")}
                            error={Boolean(touched.title && errors.title)}
                            helperText={touched.title && errors.title}
                          />
                        </Stack>
                      </Grid>
                    </>
                  )}
                  {/* <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="phoneNumber">Phone</InputLabel>
                      <TextField
                        fullWidth
                        id="phoneNumber"
                        placeholder="Phone Number"
                        {...getFieldProps("phoneNumber")}
                        error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                        helperText={touched.phoneNumber && errors.phoneNumber}
                      />
                    </Stack>
                  </Grid> */}
                  {/* <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="address">Address Line 1</InputLabel>
                      <TextField
                        fullWidth
                        id="address"
                        multiline
                        rows={2}
                        placeholder="Address"
                        {...getFieldProps("address")}
                        error={Boolean(touched.address && errors.address)}
                        helperText={touched.address && errors.address}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="address2">Address Line 2</InputLabel>
                      <TextField
                        fullWidth
                        id="address2"
                        multiline
                        rows={2}
                        placeholder="Address (Optional)"
                        {...getFieldProps("address2")}
                        error={Boolean(touched.address2 && errors.address2)}
                        helperText={touched.address2 && errors.address2}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="city">City</InputLabel>
                      <TextField
                        fullWidth
                        id="city"
                        placeholder="City"
                        {...getFieldProps("city")}
                        error={Boolean(touched.city && errors.city)}
                        helperText={touched.city && errors.city}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="state">State</InputLabel>
                      <TextField
                        fullWidth
                        id="state"
                        placeholder="State"
                        {...getFieldProps("state")}
                        error={Boolean(touched.state && errors.state)}
                        helperText={touched.state && errors.state}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="country">Country</InputLabel>
                      <TextField
                        fullWidth
                        id="country"
                        placeholder="Country"
                        {...getFieldProps("country")}
                        error={Boolean(touched.country && errors.country)}
                        helperText={touched.country && errors.country}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="zip">Zip Code</InputLabel>
                      <TextField
                        fullWidth
                        id="zip"
                        placeholder="Zip Code"
                        {...getFieldProps("zip")}
                        error={Boolean(touched.zip && errors.zip)}
                        helperText={touched.zip && errors.zip}
                      />
                    </Stack>
                  </Grid> */}
                  <Grid item xs={12} sm={12}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end" sx={{ pt: 1 }}>
                      <Button color="secondary" onClick={closeModal}>
                        Cancel
                      </Button>
                      <Button type="submit" variant="contained" disabled={isSubmitting}>
                        Save
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          {/* <Divider /> */}
          {/* <DialogActions sx={{ p: 2.5 }}> */}
          {/* <Grid item> */}
          {/* <Stack direction="row"> */}
          {/* </Stack> */}
          {/* </Grid> */}
          {/* </DialogActions> */}
        </Form>
        {/* </LocalizationProvider> */}
      </FormikProvider>
    </Box>
  );
}
