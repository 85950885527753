// import {  useState} from "react";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, Grid, Stack, Switch, Tooltip, Typography } from "@mui/material";

import { CloseOutlined } from "@ant-design/icons";
import { IconButton } from "@mui/material";
import { FormulaComponentItem, PrintFormulaInputType, PrintTypeObj } from "../../../types/formulation";
import { Radio } from "@mui/material";
import { FormControl } from "@mui/material";
import { RadioGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { printType, sanitize } from "../../../utils/utility";
import React, { useState } from "react";
import { GetFormulaType } from "../../../AllTypes";
import axiosServices from "../../../utils/axios";
import { alertMessage } from "../../pages-helpers/AlertMessage";
import useAuth from "../../../hooks/useAuth";

import { Dispatch, SetStateAction } from "react";
import { EndpointsType, SelectSeriesType } from "../../../AllTypes";

interface Props {
  showPrintModal: boolean;
  setShowPrintModal: Dispatch<SetStateAction<boolean>>;
  endpoints: EndpointsType;
  closeModal: () => void;
  data: FormulaComponentItem[];
  series: SelectSeriesType;
  formula: GetFormulaType | null;
  weight: number;
  // formulasBySeries:CustomFormulaType
}

export default function PrintFormulaModal({
  closeModal,
  data,
  series,
  formula,
  weight,
}: // formulasBySeries
Props) {
  const [printOrderType, setPrintOrderType] = useState<string>("per");
  const [deductFromInventory, setDeductFromInventory] = useState(true);
  const { user: currentUser } = useAuth();

  const handleChangeOrderType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrintOrderType(e.target.value);
  };

  const downloadData = (data: any) => {
    createAndDownloadBlobFile(data, sanitize("label-" + formula?.name));
  };

  function createAndDownloadBlobFile(body: any, filename: any, extension = "pdf") {
    const blob = new Blob([body]);
    const fileName = `${filename}.${extension}`;
    let navi: any = window.navigator;
    if (navi.msSaveBlob) {
      // IE 10+
      navi.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement("a");
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
    closeModal();
  }

  const handlePrintLabel = async (model: PrintFormulaInputType) => {
    try {
      let urlParams = "";
      if (currentUser?.locationId && deductFromInventory) {
        urlParams = `?deductFromInventory=${deductFromInventory}&locationId=${currentUser.locationId}`;
      }
      const response = await axiosServices.post(`/api/formula/label${urlParams}`, model, {
        responseType: "arraybuffer",
      });
      if (response.data) {
        downloadData(response.data);
      }
    } catch (err) {
      alertMessage("Something went wrong ", "error", err);
    }
  };
  const handlePrintReport = async (model: PrintFormulaInputType, recycledName: string) => {
    try {
      // TODO: clean model
      const { recycledFrom, recycledWeight, ...obj } = model;
      const reportModal = {
        recycledFrom: model,
        recycledWeight: recycledWeight || "0",
        recycledName: recycledName || "",
        ...obj,
      };
      if (recycledName && reportModal.recycledFrom) {
        reportModal.recycledFrom.name = recycledName;
      }

      let urlParams = "";
      if (currentUser?.locationId && deductFromInventory) {
        urlParams = `?deductFromInventory=${deductFromInventory}&locationId=${currentUser.locationId}`;
      }
      const response = await axiosServices.post(`/api/formula/report${urlParams}`, reportModal, {
        responseType: "arraybuffer",
      });
      if (response.data) {
        downloadData(response.data);
      }
    } catch (err) {
      alertMessage("Something went wrong ", "error", err);
    }
  };
  const handleClickForPrint = async (printType: PrintTypeObj) => {
    if (!formula) {
      alertMessage("No Formula Selected", "error");
      return;
    }

    let recycledFromId: number | undefined = undefined;
    let recycledWeight = "";
    let recycledName = "";
    data.forEach((d) => {
      if (d.componentId) {
        d.formulaId = undefined;
      } else if (d.formulaId) {
        d.componentId = undefined;
        recycledFromId = d.formulaId;
        recycledWeight = d.amountInGrams.toString();
        recycledName = d.componentName;
      }
    });

    const printItem: FormulaComponentItem[] = [...data];
    let sortedPrintComponent: FormulaComponentItem[] = [];
    if (printOrderType === "pc") {
      sortedPrintComponent = printItem.sort((x: FormulaComponentItem, y: FormulaComponentItem) => {
        if (x.componentName.toLowerCase() < y.componentName.toLowerCase()) {
          return -1;
        } else if (x.componentName.toLowerCase() > y.componentName.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      });
    } else {
      sortedPrintComponent = printItem.sort((x: FormulaComponentItem, y: FormulaComponentItem) => {
        if (x.amount > y.amount) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    const printLabelModal: PrintFormulaInputType = {
      id: formula.id as number,
      clientDateTime: new Date().toLocaleString(),
      companyId: formula.companyId as number,
      components: sortedPrintComponent,
      description: formula.description,
      height: printType.height,
      width: printType.width,
      locationId: -1,
      masterFormula: true,
      name: formula.name,
      rgbColors: formula.rgbColors,
      seriesId: formula.seriesId as number,
      seriesName: series.text,
      versionNumber: 1,
      weight: weight,
      userId: null,
      recycledFrom: "",
      recycledFromId: recycledFromId,
      recycledWeight: recycledWeight,
    };
    // TODO:CHECK FOR THE REPORT API CALL LABEL API CALL
    if (printType.width === 0) {
      handlePrintReport(printLabelModal, recycledName);
    } else {
      handlePrintLabel(printLabelModal);
    }
  };

  return (
    <Box sx={{ maxHeight: "90vh", width: "450px", maxWidth: "90vw" }} mx={{ width: "40vw" }}>
      <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
        <DialogTitle>
          <Typography variant="h2" fontWeight="bold" component="span">
            Print Formula
          </Typography>
        </DialogTitle>

        <Tooltip title="Close">
          <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={closeModal} edge="start">
            <CloseOutlined />
          </IconButton>
        </Tooltip>
      </Stack>
      <Divider />
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" spacing={3}>
              <span>Order By</span>
              <FormControl component="fieldset">
                <RadioGroup aria-label="type" defaultValue="grams" name="radio-buttons-group" row>
                  <Box sx={{ fontSize: "20px" }}>
                    <FormControlLabel
                      value="percentage"
                      control={<Radio onChange={handleChangeOrderType} value="per" checked={printOrderType === "per"} />}
                      label="Percentage"
                    />
                    <FormControlLabel
                      value="grams"
                      control={<Radio onChange={handleChangeOrderType} value="pc" checked={printOrderType === "pc"} />}
                      label="Product code"
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </Stack>
            <FormControlLabel
              labelPlacement="end"
              control={<Switch size="small" checked={deductFromInventory} onChange={(e) => setDeductFromInventory(e.target.checked)} />}
              label={`Deduct from Inventory`}
            />
            <Grid item xs={12} p={2}>
              {printType.map((item: PrintTypeObj, index: number) => {
                return (
                  <Box mt={2} key={index}>
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      style={{ padding: "8px 0px 8px 0px" }}
                      onClick={() => handleClickForPrint(item)}
                      fullWidth
                    >
                      {item.text}
                    </Button>
                  </Box>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 2.5 }}>
        <Button type="submit" variant="contained" onClick={closeModal}>
          Cancel
        </Button>
      </DialogActions>
    </Box>
  );
}
